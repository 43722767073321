import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql.macro'
import { isLogin } from './Library'

// order_list.gql에 totalCount를 넣으면 페이징에서 에러남, 여기서 단독처리.
const orderListGql = gql`
	query getOrderCount($gid: Float!, $status: String) {
		gongOrders(gid: $gid, status: $status) {
			totalCount
		}
	}
`

export default function Footer() {
	useEffect(() => { return () => unmounted.current = true }, [])
	const unmounted = useRef(false)
	const values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		status: "PAY,WORKING",
	}

	const client = useApolloClient();
	if (!localStorage.liveOrderCnt || !localStorage.access_token) localStorage.liveOrderCnt = 0;

	// 로그인 했을때만 서버 호출하도록 수정
	const [liveOrderCnt, setOrderCount] = useState(localStorage.liveOrderCnt);
	if (isLogin()) {
		client.query({
			query: orderListGql,
			variables: values,
			fetchPolicy: 'network-only',
		}).then((data) => {
			localStorage.liveOrderCnt = data.data.gongOrders.totalCount;
			if (unmounted.current === true) return /* '...memory leak...'에러 방지용, 다른페이지로 이동하여 footer가 언마운트되었을 때 set state하면 발생함.*/
			setOrderCount(data.data.gongOrders.totalCount);
		}).catch(() => {
			if (unmounted.current === true) return /* '...memory leak...'에러 방지용, 다른페이지로 이동하여 footer가 언마운트되었을 때 set state하면 발생함.*/
			setOrderCount(localStorage.liveOrderCnt)
		});
	}

	return (
		<nav className="tabBar">
			<NavLink to="/" exact activeClassName="active" className="txtHide btnHome">홈</NavLink>
			<ul>
				<li><NavLink to="/shop" activeClassName="active"><span>매장보기</span></NavLink></li>
				<li><NavLink to="/order" activeClassName="active"><span>주문내역</span><em className="num">{liveOrderCnt}</em></NavLink></li>
				<li></li>
				<li><NavLink to="/search" activeClassName="active"><span>검색</span></NavLink></li>
				<li><NavLink to="/mypage" activeClassName="active"><span>MY</span></NavLink></li>
			</ul>
		</nav>
	)
}