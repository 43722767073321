import React from "react";


export default class Popup extends React.Component {
	constructor(props) {
		super(props);
		
		this.handelClick = this.handelClick.bind(this);
	}

	handelClick(e) {
		/* global AppScheme */
		if (typeof AppScheme !== "undefined") {
			if (this.props.type === 'NEW') {
				e.preventDefault();
				AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://outbrowser?url=${encodeURIComponent(this.props.href)}`);
			} else if (this.props.type !== 'BLANK' && this.props.type !== 'MOVE') {
				e.preventDefault();
				AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://inbrowser?url=${encodeURIComponent(this.props.href)}`);
			}
		}
	}
	
	render() {
		let target = this.props.type === 'MOVE' ? '_self' : '_blank';
		return (
			<a href={this.props.href} target={target} rel="noopener noreferrer" className={this.props.className} onClick={this.handelClick}>{this.props.children}</a> 
		)
	}
}