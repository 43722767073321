import React, { useEffect, useRef } from "react";
import $ from 'jquery'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import { useQuery } from '@apollo/client';
import { Link, useParams, useLocation } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { gqlErrorHandler } from './Library'

const shopCatgoryGql = loader('../gql/shop_category.gql')

export default function CategoryMenu(props) {
	//let position = 0
	const menuEffectedRef = useRef(false);
	const { sUrl, doScrollAnimation } = props

	const { category: cateCode } = useParams();
	const { search } = useLocation()
	const { loading, error, data } = useQuery(shopCatgoryGql, { variables: { gid: process.env.REACT_APP_SERVICE_GID } });

	useEffect(() => {
		if (menuEffectedRef.current || loading) return /* 처음의gql요청에서 데이터 가져오기전엔 안함 */
		menuEffectedRef.current = true /* 한번만 함 */

		let position = 0;
		if (typeof cateCode !== 'undefined') {
			for (const p in data.gong.category.edges) {
				if (data.gong.category.edges[p].node.pk === parseInt(cateCode)) {
					position = parseInt(p) + 1;
					break;
				}
			}
		}

		var _hdMenuSwipe = new Swiper('#header .swiper-container', {
			slidesPerView: 'auto',
			initialSlide: position,
		});

		if (doScrollAnimation) { // 매장보기 리스트형만 해당
			// console.log('add event!')
			$('#reWrap').on('scroll', function () {
				hdScrollWrap();
				hdScrollCommon();
			});
			$(window).on('scroll', function () {
				hdScrollWin();
				hdScrollCommon();
			});
			var subHeaderTimeout, hdMenuSwipeTimeout;
			function hdScrollCommon() {
				clearTimeout(subHeaderTimeout);
				clearTimeout(hdMenuSwipeTimeout);
				subHeaderTimeout = setTimeout(
					function () {
						$('.subHeader2').next('.gap').height($('.subHeader2').outerHeight());
					}
					, 50
				);
				hdMenuSwipeTimeout = setTimeout(
					function () {
						_hdMenuSwipe.update();
					}
					, 310
				);
			}
			/**
			 * jquery-ui없이 easing표과 사용하기
			 * 참고: https://stackoverflow.com/questions/5207301/jquery-easing-functions-without-using-a-plugin
			 */
			if (!$.easing.easeOutCubic) {
				$.easing.easeOutCubic = function (x, t, b, c, d) {
					return c * ((t = t / d - 1) * t * t + 1) + b;
				}
			}
			function hdScrollWrap() {
				if ($('#reWrap').scrollTop() === 0) {
					$('#header').removeClass('scroll');
					$('.hdMenu ul li').stop().animate({
						"float": "left",
						"text-align": "center",
						"width": "auto",
						"min-width": "41px",
						"margin-right": "14px"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
					$('.hdMenu ul li a').stop().animate({
						"display": "block",
						"font-size": "12px",
						"color": "#000000",
						"padding": "60px 0 0 0",
						"border-radius": "0",
						"border": "none"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
					$('.hdMenu ul .on').stop().animate({
						"padding-top": "0"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
					$('.hdMenu ul .on a').stop().animate({
						"padding": "58px 0 0 0",
						"border-radius": "0",
						"position": "relative",
						"font-size": "13px",
						"font-weight": "bold",
						"min-width": "49px",
						"color": "#000000"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
				} else {
					$('#header').addClass('scroll');
					$('#header.subHeader2.scroll .hdMenu ul li a').stop().animate({
						"padding": "0 10px 0 30px",
						"background": "#ffffff",
						"border": "1px solid #cccccc",
						"border-radius": "100px"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
					$('#header.subHeader2.scroll .hdMenu ul .on').stop().animate({
						"padding-top": "10px"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
					$('#header.subHeader2.scroll .hdMenu ul .on a').stop().animate({
						"padding": "0 10px 0 32px",
						"background": "#000000",
						"color": "#ffffff",
						"border-radius": "100px"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
				}
			}
			function hdScrollWin() {
				if ($(window).scrollTop() === 0) {
					$('#header').removeClass('scroll');
					$('.hdMenu ul li').stop().animate({
						"float": "left",
						"text-align": "center",
						"width": "auto",
						"min-width": "41px",
						"margin-right": "14px",
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
					$('.hdMenu ul li a').stop().animate({
						"display": "block",
						"font-size": "12px",
						"color": "#000000",
						"padding": "60px 0 0 0",
						"border-radius": "100px",
						"border": "none"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
					$('.hdMenu ul .on a').stop().animate({
						"padding": "58px 0 0 0",
						"border-radius": "0",
						"position": "relative",
						"font-size": "13px",
						"font-weight": "bold",
						"min-width": "49px",
						"color": "#000000"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
				} else {
					$('#header').addClass('scroll');
					$('#header.subHeader2.scroll .hdMenu ul li a').stop().animate({
						"background": "#ffffff",
						"border": "1px solid #cccccc",
						"border-radius": "100px"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
					$('#header.subHeader2.scroll .hdMenu ul .on a').stop().animate({
						"background": "#000000",
						"border-radius": "100px"
					}, { queue: false, duration: 300, easing: 'easeOutCubic' });
				}
			}
			hdScrollWrap();
			hdScrollWin();


		}

	})
	useEffect(() => {
		return () => {
			// console.log('remove event!')
			$('#reWrap').off('scroll')
			$(window).off('scroll')
		}
	}, [])
	useEffect(() => {
		if (document.querySelector('#header .swiper-container')) {
			let swiper = document.querySelector('#header .swiper-container').swiper;
			if (typeof props.activeIndex === 'number') {
				swiper.slideTo(props.activeIndex, 300, false);
			}
		}
	}, [props.activeIndex])


	if (loading) { return null; }
	if (error) { gqlErrorHandler(error); return null; }

	/*
	const directToCateCode = localStorage.getItem('directToCateCode')
	if (directToCateCode) {
		if ('oneteam' === directToCateCode) {
			position = data.gong.category.edges.length + 1
		} else {
			for (const p in data.gong.category.edges) {
				if (data.gong.category.edges[p].node.pk === parseInt(directToCateCode)) {
					position = parseInt(p) + 1 // 여기서 position=0으로 하면 안먹힘... 처음으로 가려면 어떻게?
					break
				}
			}
		}
		localStorage.removeItem('directToCateCode')
	}
	*/
	
	const cateCodeImgClass = {
		36: "hdM2", //카페/디저트
		37: "hdM3", //한식
		38: "hdM4", //분식
		39: "hdM5", //일식/회
		40: "hdM6", //양식/돈까스
		41: "hdM7", //중식
		42: "hdM8", //치킨/피자
		43: "hdM9", //족발/보쌈
		44: "hdM10", //야식
		45: "hdM11", //찜/탕
		46: "hdM12", //도시락
		47: "hdM13", //패스트푸드
		50: "hdM14", //청과/정육
		54: "hdM15", //청과/정육
		55: "hdM16", //청과/정육
	}
	return (
		<div className="swiper-container">
			<ul className="swiper-wrapper">
				<li className={`swiper-slide hdM1 ${!cateCode ? 'on' : ''}`}><Link to={`${sUrl}${search}`} replace><span>전체</span></Link></li>
				{data.gong.category.edges.map((o) => (
					<li className={`swiper-slide ${cateCodeImgClass[o.node.pk]} ${parseInt(cateCode) === o.node.pk ? 'on' : ''}`} key={o.node.pk}><Link to={`${sUrl}/${o.node.pk}${search}`} ><span>{o.node.name}</span></Link></li>
				))}
			</ul>
		</div>
	)
}