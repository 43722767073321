import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import Popup from './Popup';

class TermsList extends React.Component {
	render() {
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>제공 서비스별 약관 </h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<div className="mpListMenu">
							<ul>
								<li><Popup href="https://smallbiz.notion.site/_-9ce2143a0eb7495185c6bd8e04ad8ef8">통합회원 이용약관</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/go_-9582b6c029aa4036bad0576fdd647466">서비스 이용약관</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/go_-9858e92d11ab4cc0a11596c2e3bb4311">개인정보처리방침</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/go_-379194ad854f494e90df7cc094d9703a">위치기반 서비스 이용약관</Popup></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(TermsList)