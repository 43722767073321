export default function (shopPaytype) {
	let payType = []
	for (const key in shopPaytype) {
		let item = shopPaytype[key]
		if (item === 'SHOP' || item === 'SHIP') item = 'SHOP_SHIP' /* 매장에서 결제, 만나서 결제 는 모두 현장으로 표시 */
		if (!payType.includes(item)) payType = [...payType, item]
	}
	/* 카드결제->현장결제->오륙도페이 순으로 */
	if (payType.includes('KNPG')) payType = ['KNPG', ...payType]
	if (payType.includes('SHOP_SHIP')) payType = ['SHOP_SHIP', ...payType]
	if (payType.includes('KSPG')) payType = ['KSPG', ...payType]

	payType = [...new Set(payType)] /* unique처리 */

	let payTypeName = []
	payType.forEach((item) => {
		let name = false
		!name && (name = item === 'KSPG' && '카드')
		!name && (name = item === 'SHOP_SHIP' && '현장')
		!name && (name = item === 'KNPG' && '오륙도페이')
		name && (payTypeName = [...payTypeName, name])
	})
	return payTypeName
}